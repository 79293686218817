import { lazy, LazyExoticComponent, ReactElement } from 'react'

const Home = lazy(() => import('../Home'))

const appSlug = 'geoloc'

export type NavElement = {
  icon: string;
  title: string;
  path: string;
  component: LazyExoticComponent<() => ReactElement>;
  role: Array<string>;
}

const navRoutes: NavElement[] = [
  {
    icon: '',
    title: '',
    path: '',
    component: Home,
    role: [`${appSlug}::ACCESS`],
  },
]

export default navRoutes
