/* eslint-disable camelcase */
import { LayerProps } from 'react-map-gl'

export type LayerProperties = {[layerName: string]: LayerProps}

export type ViewType = 'geo' | 'sch' | 'line_geo' | 'line_geo_centroid' |
'full_rgi_line_geo' | 'full_rgi_line_geo_centroid' | 'full_rgi_track_geo_centroid'

export type RpFeatures = {
  code_ci: string;
  code_ch: string;
  libelle_court_ch: string;
  libelle_long_ch: string;
  id: string;
  libelle: string;
}

export enum BorderType {
 eic = 'eic',
 admin = 'admin'
}

type Layer = {
  name: string;
  layer: string;
}
export type HideableLayers = {
  category: string;
  layers: Layer[]

}
