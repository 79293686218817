import { Feature } from 'geojson'
import arrowgreen from 'assets/icons/arrowgreen.png'
import arrowred from 'assets/icons/arrowred.png'
import arrowyellow from 'assets/icons/arrowyellow.png'
import arrowgrey from 'assets/icons/arrowgrey.png'
import arrowgreenterminus from 'assets/icons/arrowgreenterminus.png'
import arrowredterminus from 'assets/icons/arrowredterminus.png'
import arrowyellowterminus from 'assets/icons/arrowyellowterminus.png'
import arrowgreyterminus from 'assets/icons/arrowgreyterminus.png'
import circlegreen from 'assets/icons/circlegreen.png'
import circlered from 'assets/icons/circlered.png'
import circleyellow from 'assets/icons/circleyellow.png'
import circlegrey from 'assets/icons/circlegrey.png'
import circlegreenterminus from 'assets/icons/circlegreenterminus.png'
import circleredterminus from 'assets/icons/circleredterminus.png'
import circleyellowterminus from 'assets/icons/circleyellowterminus.png'
import circlegreyterminus from 'assets/icons/circlegreyterminus.png'

export const LATE_THRESHOLD = 5
export const VERY_LATE_THRESHOLD = 14

export const getCirculationClassName = (feature: Feature): string => {
  const delay = feature.properties?.delay
  if (delay === null || delay === undefined) return 'undeterminate'
  if (delay <= LATE_THRESHOLD) {
    return 'on-time'
  } if (delay <= VERY_LATE_THRESHOLD) {
    return 'late'
  }
  return 'very-late'
}

export const getCirculationIcon = (feature: Feature): string => {
  const delay = feature.properties?.delay

  if (feature.properties?.angle !== null) {
    if (delay === null || delay === undefined) {
      return feature.properties?.terminus ? arrowgreyterminus : arrowgrey
    } if (delay <= LATE_THRESHOLD) {
      return feature.properties?.terminus ? arrowgreenterminus : arrowgreen
    } if (delay <= VERY_LATE_THRESHOLD) {
      return feature.properties?.terminus ? arrowyellowterminus : arrowyellow
    }
    return feature.properties?.terminus ? arrowredterminus : arrowred
  }
  if (delay === null || delay === undefined) {
    return feature.properties?.terminus ? circlegreyterminus : circlegrey
  } if (delay <= LATE_THRESHOLD) {
    return feature.properties?.terminus ? circlegreenterminus : circlegreen
  } if (delay <= VERY_LATE_THRESHOLD) {
    return feature.properties?.terminus ? circleyellowterminus : circleyellow
  }
  return feature.properties?.terminus ? circleredterminus : circlered
}

export default {
  getCirculationClassName, getCirculationIcon, LATE_THRESHOLD, VERY_LATE_THRESHOLD,
}
