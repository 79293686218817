import { auth } from '@osrdata/app_core'
import 'App.scss'
import AccessDenied from 'components/AccessDenied/AccessDenied'
import Loader from 'components/Loader'
import PrivateRoute from 'components/PrivateRoute/PrivateRoute'
import StatusStream from 'components/StatusStream/StatusStream'
import TopBar from 'components/TopBar/TopBar'
import history from 'customHistory'
import {
  ReactElement, Suspense, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Router, Switch,
} from 'react-router-dom'
import { RootState } from 'Store'
import terms from 'terms.json'
import navRoutes from 'utils/navRoutes'

const APP_NAME = 'geoloc'
const ACCESS_PERM = 'geoloc::ACCESS'

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged, appPermissions, isLoading } = useSelector((state: RootState) => state.user)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
      }
    }
  }, [isLogged, appPermissions])

  const innerComponent = (
    <div style={{
      flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
    }}
    >
      <StatusStream />
    </div>
  )

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <TopBar
            appName={APP_NAME}
            innerComponent={innerComponent}
            hasAccess={!isLoading && !accessDenied}
          />

          {isLoading && <div id="app"><Loader message={terms.Common.loading} /></div>}
          {!isLoading && (accessDenied ? <AccessDenied /> : (
            <div id="app">
              <Switch>
                {navRoutes.map(route => (
                  <PrivateRoute
                    key={route.path}
                    path={route.path}
                    allowed={route.role}
                  >
                    <route.component />
                  </PrivateRoute>
                ))}
              </Switch>
            </div>
          ))}
        </Router>
      </Suspense>
    </>
  )
}
