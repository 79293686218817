import config from 'config/config'

const MAP_LAYER_SOURCE = {
  track: 'cassini_v2_gaia_habillage_valeur_margin_100',
  pk: 'cassini_reperekilometrique',
  pr: 'cassini_v2_gaia_point_remarquable',
  bv: 'cassini_v2_gaia_gare',
}

const LAYER_NAMES = {
  bvLayer: 'bv-layer',
  prLayer: 'pr-layer',
  circulationLayer: 'circulation-layer',
  trackLayer: 'track-layer',
  pkLayer: 'pk-layer',
  regionLayer: 'region-layer',
  circulationUndeterminateLayer: 'circulation-layer-undeterminate',
  circulationOnTimeLayer: 'circulation-layer-on-time',
  circulationLateLayer: 'circulation-layer-late',
  circulationVeryLateLayer: 'circulation-layer-very-late',
}

const LAYER_URL = `${config.proxy}/chartis/v2/layer`

export { MAP_LAYER_SOURCE, LAYER_URL, LAYER_NAMES }
