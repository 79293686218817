import { LATE_THRESHOLD, VERY_LATE_THRESHOLD } from './Popups/ClusterPopup/utils'

const getCSSValue = (name: string): string => getComputedStyle(document.documentElement).getPropertyValue(name)
export const getDelayClass = (delay: number): string => {
  if (delay <= LATE_THRESHOLD) {
    return 'on-time'
  }
  if (delay <= VERY_LATE_THRESHOLD) {
    return 'late'
  }
  return 'very-late'
}

export default getCSSValue
