/* eslint-disable max-len */
export const terms = {
  Common: {
    loading: 'Chargement',
    create: 'Créer',
    pendingQuery: 'Requête en cours...',
    Error: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application',
      appSubtitle: 'Veuillez contacter votre administrateur',
      exitButton: 'Retour au portail des applications',
      server: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 500)',
      unknown: 'Une erreur est survenue.',
      notFound: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 404)',
      badRequest: 'Une erreur est survenue. Vérifiez les informations que vous avez entrées et réessayez.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
    },
    name: 'Nom',
    description: 'Description',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    add: 'Ajouter',
  },
  Map: {
    Toolbar: {
      lightTheme: 'Fond de carte sobre',
      fullTheme: 'Fond de carte riche',
      resetMapView: 'Réinitialiser la vue',
      sncfRegion: 'SNCF',
      adminRegion: 'Administratives',
      borders: 'Frontières',
      mapStyleLayers: 'Fond de carte',
      OSMRails: 'Voies ferrées OSM',
    },
    Popup: {
      Circulation: {
        type: 'Type',
        number: 'N°',
        origin: 'ORIGINE',
        passage: 'PASSAGE',
        departure: 'DÉPART',
        arrival: 'ARRIVÉE',
        terminus: 'TERMINUS',
        noTimeline: 'Aucune information de circulation',
        minutes: 'min',
        delay: 'Retard',
        undeterminateDelay: 'Indéterminé',
        isitrackLink: 'Lien vers ISITRAC',
        ligne: 'Ligne',
        pk: 'PK',
        pr: 'Point Remarquable',
        codeComposition: 'Code Composition',
      },
      ci: 'Code CI',
      ch: 'Code CH',
      ciLibelle: 'Libellé CI',
      chLibelle: 'Libellé CH',
      gaiaId: 'ID Gaïa',
      eic: 'EIC',
    },
    Layer: {
      Category: {
        infrastructure: 'Infrastructure',
        circulationState: 'État des circulations',
      },
      pr: 'Points remarquables',
      pk: 'Points kilométriques',
      station: 'Gares',
      track: 'Lignes',
      onTime: 'A l’heure (≤ 5 minutes)',
      late: 'Léger retard (5 minutes - 15 minutes)',
      veryLate: 'Retard important (≥ 15 minutes)',
      undeterminate: 'Retard indéterminé',
    },
  },
  Menu: {
    setInterval: 'Définir un intervalle',
    includeIndeterminate: 'Inclure les circulations avec un retard indéterminé',
  },
  Search: {
    placeholder: 'Rechercher une circulation en saisissant les 3 premiers caractères.',
    resultsCount: (count: number) => `${count} élément${count > 1 ? 's' : ''} trouvé${count > 1 ? 's' : ''}`,
  },
  Stream: {
    streamName: 'BASIC',
  },
  MultiTrainFilter: {
    button: 'Filtrer les circulations',
    popup: {
      title: 'N\'afficher que les circulations ci-dessous',
      instructions: 'Entrez un ou plusieurs numéros de circulations',
      instructionsHint: ' (séparés par un espace)',
      button: {
        validate: 'Continuer',
        edit: 'Ajouter des circulations au filtre',
        filter1: 'Filtrer sur ',
        filter2: ' circulation(s)',
        reset: 'Supprimer le filtre',
      },
      warning1: 'Attention, ',
      warning2: ' numéro(s) n\'on pas pu être trouvé(s)  :',
    },
  },
  ClusterPopup: {
    circulations: 'circulations',
    veryLate: 'en retard important',
    late: 'en léger retard',
    onTime: 'à l\'heure',
    undeterminate: 'avec un retard indéterminé',
  },
  StatusStream: {
    observations: 'Observations',
    theoricalTrains: 'Circulations théoriques',
  },
}

export default terms
