/* eslint-disable import/prefer-default-export */
import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { CirculationDetails } from 'components/CirculationStream/types'
import { GetParams } from './types'

const getCirculationData = createAsyncThunk(
  'circulation/getCirculationData',
  async (params: GetParams, thunkApi) => {
    try {
      const response: CirculationDetails = await
      get(`/geoloc/timeline/${params.circulationId}/`)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getCirculationTypes = createAsyncThunk(
  'circulation/getCirculationTypes',
  async (_, thunkApi) => {
    try {
      const response: {[key: string]: string[]} = await get('/geoloc/typology/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

export {
  getCirculationData,
  getCirculationTypes,
}
