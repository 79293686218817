import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  StreamState, Alerts, CirculationFilter, CirculationTypeFilter,
} from 'components/CirculationStream/types'
import { FeatureCollection } from 'geojson'
import { getCirculationData, getCirculationTypes } from './circulation.thunk'
import { DEFAULT_CIRCULATION_TYPES, EMPTY_CIRCULATION_DETAILS, EMPTY_FEATURE_COLLECTION } from './const'

const initialState: StreamState = {
  circulationStream: EMPTY_FEATURE_COLLECTION,
  circulationDetails: EMPTY_CIRCULATION_DETAILS,
  circulationNumber: '',
  circulationDetailsLoading: false,
  modifiedCirculationIds: [],
  basicAlerts: {
    source: 'basic',
    status: 'OK',
    alerts: [],
    op: 'alert',
  },
  circulationFilter: {
    active: false,
    min: -100000,
    max: 100000,
    undeterminate: false,
  },
  multiTrainFilter: {
    active: false,
    trainNumbers: [],
    openModal: false,
  },
  circulationTypeFilter: {},
  status: {
    observations: false,
    theoricalTrains: false,
  },
}

export const circulationSlice = createSlice({
  name: 'circulation',
  initialState,
  reducers: {
    resetStream: () => initialState,
    setStatus: (state, action: PayloadAction<{observations: boolean, theoricalTrains: boolean}>) => {
      state.status = action.payload
    },
    updateCirculationStream: (state, action: PayloadAction<FeatureCollection>) => {
      state.circulationStream = action.payload
    },
    updateCirculationNumber: (state, action: PayloadAction<string>) => {
      state.circulationNumber = action.payload
    },
    updateBasicAlerts: (state, action: PayloadAction<Alerts>) => {
      state.basicAlerts = action.payload
    },
    setCirculationFilter: (state, action: PayloadAction<CirculationFilter>) => {
      state.circulationFilter = action.payload
    },
    openMultiTrainModal: (state, action: PayloadAction<boolean>) => {
      state.multiTrainFilter.openModal = action.payload
    },
    applyMultiTrainFilter: (state, action: PayloadAction<string[]>) => {
      state.multiTrainFilter.trainNumbers = action.payload
      state.multiTrainFilter.active = true
      state.multiTrainFilter.openModal = false
    },
    resetMultiTrainFilter: state => {
      state.multiTrainFilter.trainNumbers = []
      state.multiTrainFilter.active = false
      state.multiTrainFilter.openModal = false
    },
    updateCirculationTypeFilter: (state, action: PayloadAction<CirculationTypeFilter>) => {
      state.circulationTypeFilter = action.payload
    },
    toggleCirculationUndeterminate: state => {
      state.circulationFilter.undeterminate = !state.circulationFilter.undeterminate
    },
    updateModifiedCirculationIds: (state, action: PayloadAction<string[]>) => {
      state.modifiedCirculationIds = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getCirculationData.pending, (state, action) => {
      if (action.meta.arg.update) return
      state.circulationDetails = initialState.circulationDetails
      state.circulationDetailsLoading = true
    })
    builder.addCase(getCirculationData.fulfilled, (state, action) => {
      state.circulationDetails = action.payload
      state.circulationDetailsLoading = false
    })
    builder.addCase(getCirculationData.rejected, state => {
      state.circulationDetailsLoading = false
    })
    builder.addCase(getCirculationTypes.fulfilled, (state, action) => {
      state.circulationTypeFilter = Object.entries(action.payload).reduce((acc, [key, value]) => {
        if (value.length === 0) return { ...acc, [key]: DEFAULT_CIRCULATION_TYPES.includes(key) }
        return {
          ...acc,
          [key]: value.reduce((acc2, subtype) => ({ ...acc2, [subtype]: DEFAULT_CIRCULATION_TYPES.includes(key) }), {}),
        }
      }, {})
    })
  },
})

export const {
  resetStream, setStatus, updateCirculationStream, updateModifiedCirculationIds,
  updateCirculationNumber, updateBasicAlerts, setCirculationFilter, toggleCirculationUndeterminate,
  openMultiTrainModal, applyMultiTrainFilter, resetMultiTrainFilter, updateCirculationTypeFilter,
} = circulationSlice.actions

export default circulationSlice.reducer
