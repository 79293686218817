import { FeatureCollection } from 'geojson'

const EMPTY_FEATURE_COLLECTION: FeatureCollection = {
  type: 'FeatureCollection', features: [],
}

const EMPTY_CIRCULATION_DETAILS = {
  circulation_id: 'pr',
  type: '',
  retard: 0,
  timeline: [],
  origine: '',
  terminus: '',
  date_heure_depart: '',
  numero_marche_depart: '',
  display_ligne_pk: false,
  ligne: '',
  pk: '',
  code_composition: '',
}

const DEFAULT_CIRCULATION_TYPES = [
  'Grandes lignes',
  'Transport Île-de-France Mobilités',
  'Transport régional',
  'Fret',
]

export { EMPTY_FEATURE_COLLECTION, EMPTY_CIRCULATION_DETAILS, DEFAULT_CIRCULATION_TYPES }
