import { LAYER_NAMES } from 'components/Layers/const'
import { ViewportProps } from 'react-map-gl'

enum MapTheme {
  light = 'light',
  full = 'full'
}

const DEFAULT_VIEWPORT: ViewportProps = {
  latitude: 46.8025,
  longitude: 2.7643,
  zoom: 5.4389429465554,
  minZoom: 4.5,
  bearing: 0,
  pitch: 0,
}
const INTERACTIVE_CIRCULATION_LAYERS = [LAYER_NAMES.circulationLayer,
  `${LAYER_NAMES.circulationLayer}-number`, `${LAYER_NAMES.circulationLayer}-cluster`]

const INTERACTIVE_LAYERS = [
  LAYER_NAMES.prLayer, LAYER_NAMES.bvLayer, `${LAYER_NAMES.bvLayer}-icon`, ...INTERACTIVE_CIRCULATION_LAYERS,
]

const MAX_BOUNDS = {
  minLat: 41.3,
  maxLat: 51.1,
  minLong: -5.1,
  maxLong: 9.6,
}

export {
  MapTheme, DEFAULT_VIEWPORT, INTERACTIVE_CIRCULATION_LAYERS, INTERACTIVE_LAYERS, MAX_BOUNDS,
}
