import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BorderType } from 'components/Layers/types'
import { DEFAULT_VIEWPORT, MapTheme } from 'components/Map/const'
import { FlyToInterpolator, ViewportProps, WebMercatorViewport } from 'react-map-gl'
import { LAYER_NAMES } from 'components/Layers/const'
import { MapState } from '../types'
import { getCirculationsViewport } from './map.thunk'

const initialState: MapState = {
  viewport: DEFAULT_VIEWPORT,
  currentOsmTheme: MapTheme.full,
  osmRails: true,
  mapStyleLoading: true,
  viewMode: 'geo',
  currentBorderType: BorderType.admin,
  activeLayers: [
    LAYER_NAMES.prLayer, LAYER_NAMES.circulationLateLayer, LAYER_NAMES.circulationOnTimeLayer,
    LAYER_NAMES.circulationVeryLateLayer, LAYER_NAMES.circulationUndeterminateLayer,
    LAYER_NAMES.pkLayer, LAYER_NAMES.bvLayer, LAYER_NAMES.trackLayer,
  ],
  trackColor: '#0084D4',
}

export const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    updateViewport: (state, action: PayloadAction<ViewportProps>) => {
      state.viewport = action.payload
    },
    updateTheme: (state, action: PayloadAction<MapTheme>) => {
      state.currentOsmTheme = action.payload
    },
    updateBorders: (state, action: PayloadAction<BorderType |null>) => {
      state.currentBorderType = action.payload
    },
    addActiveLayer: (state, action: PayloadAction<string>) => {
      state.activeLayers.push(action.payload)
    },
    removeActiveLayer: (state, action: PayloadAction<string>) => {
      state.activeLayers = state.activeLayers.filter(layer => layer !== action.payload)
    },
    updateTrackColor: (state, action: PayloadAction<string>) => {
      state.trackColor = action.payload
    },
    toggleOSMRails: state => {
      state.osmRails = !state.osmRails
      state.mapStyleLoading = true
    },
    mapStyleLoaded: state => {
      state.mapStyleLoading = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getCirculationsViewport.fulfilled, (state, action) => {
      const { longitude, latitude, zoom } = new WebMercatorViewport(state.viewport as { width: number, height: number })
        .fitBounds(action.payload, { padding: 50 })
      state.viewport = {
        ...state.viewport,
        longitude,
        latitude,
        zoom,
        transitionDuration: 500,
        transitionInterpolator: new FlyToInterpolator(),
      }
    })
  },
})

export const {
  updateViewport, updateTheme, updateBorders, addActiveLayer, removeActiveLayer, updateTrackColor, toggleOSMRails,
  mapStyleLoaded,
} = mapSlice.actions

export default mapSlice.reducer
