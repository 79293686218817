import { useSelector } from 'react-redux'
import CircleIcon from '@mui/icons-material/Circle'
import terms from 'common/terms'
import { RootState } from 'Store'
import './StatusStream.scss'

const StatusStream = () => {
  const { status } = useSelector((state: RootState) => state.circulation)
  return (
    <div className="status-stream">
      <div className="item">
        <span className="label">{terms.StatusStream.observations}</span>
        <CircleIcon className={`icon${status.observations ? ' success' : ''}`} />
      </div>
      <div className="item">
        <span className="label">{terms.StatusStream.theoricalTrains}</span>
        <CircleIcon className={`icon${status.theoricalTrains ? ' success' : ''}`} />
      </div>
    </div>
  )
}

export default StatusStream
