/* eslint-disable import/prefer-default-export */
import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetParams } from './types'

const getCirculationSearch = createAsyncThunk(
  'circulation/getCirculationSearch',
  async (params: GetParams, thunkApi) => {
    try {
      const response = await get(`/geoloc/search/${params.prefixCirculationNumber}/`)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

export {
  getCirculationSearch,
}
