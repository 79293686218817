import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CirculationSearch } from './types'
import { getCirculationSearch } from './search.thunk'

const results: CirculationSearch[] = []
const initialState = {
  results,
  loading: false,
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    reset: () => initialState,
    update: (state, action: PayloadAction<CirculationSearch[]>) => {
      state.results = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getCirculationSearch.pending, state => {
      state.loading = true
    })
    builder.addCase(getCirculationSearch.fulfilled, (state, action: PayloadAction<CirculationSearch[]>) => {
      state.results = action.payload
      state.loading = false
    })
    builder.addCase(getCirculationSearch.rejected, state => {
      state.loading = false
    })
  },
})

export const {
  reset, update,
} = searchSlice.actions

export default searchSlice.reducer
