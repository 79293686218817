/* eslint-disable import/prefer-default-export */
import { post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'

const getCirculationsViewport = createAsyncThunk(
  'map/getCirculationsViewport',
  async (circulationIds: string[], thunkApi) => {
    try {
      const response = await post('/geoloc/bounding-box/', circulationIds)
      return [response[3], response[1]] as [[number, number], [number, number]]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

export {
  getCirculationsViewport,
}
