import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment-timezone'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { PersistGate } from 'redux-persist/integration/react'
import { initTranslation } from '@osrdata/app_core/dist/translation'
import { store, persistor } from './Store'
import * as serviceWorker from './serviceWorker'

import '@sncf/bootstrap-sncf.metier/dist/bootstrap-sncf.min.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import 'variables.scss'
import App from './App'
import './index.css'

Sentry.init({
  dsn: 'https://35ca269108cf4688baf291d2c61689ba@sentry.shared.dgexsol.fr/72',
  environment: process.env.REACT_APP_REALM,
  integrations: [new BrowserTracing()],
  sampleRate: 1.0,
  tracesSampleRate: 0.01,
})

initTranslation()

moment.tz.setDefault('Europe/Paris')

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
