import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AppState {
  error: null | undefined,
  fullscreen: boolean,
  displayToolbar: string,
}

const initialState: AppState = {
  error: null,
  fullscreen: false,
  displayToolbar: '',
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleFullscreen: state => {
      state.fullscreen = !state.fullscreen
    },
    toggleDisplayToolbar: (state, action: PayloadAction<string>) => {
      state.displayToolbar = action.payload
    },
    getError: (state, action: PayloadAction<null>) => {
      state.error = action.payload
    },
  },
})

export const { toggleFullscreen, getError, toggleDisplayToolbar } = appSlice.actions

export default appSlice.reducer
